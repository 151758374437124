<template>
  <div class="py-2">
    <div class="relative grid grid-cols-2 md:grid-cols-4 gap-6">
      <div>
        <CTypo tstyle="caption2" class="text-gray-500">
          {{ $t('balance') }}
        </CTypo>
        <CTypo tstyle="title1" class="text-primary">
          {{ $n(balanceTotalAmount, 'currency') }}
        </CTypo>
      </div>
      <div>
        <CTypo tstyle="caption2" class="text-gray-500">
          {{ $t('availableForPayout') }}
        </CTypo>
        <CTypo tstyle="title1" class="text-gray-800">
          {{ $n(balanceAvailableAmount, 'currency') }}
        </CTypo>
        <CLink
          v-if="balanceAvailableAmount > 0"
          @click="openModal"
        >
          {{ $t('payouts.start') }}
        </CLink>
      </div>
      <div>
        <CTypo tstyle="caption2" class="text-gray-500">
          {{ $t('pendingForPayout') }}
        </CTypo>
        <CTypo tstyle="title1" class="text-gray-800">
          {{ $n(balancePendingAmount, 'currency') }}
        </CTypo>
      </div>
      <div>
        <CTypo tstyle="caption2" class="text-gray-500">
          {{ $t('pendingPayout') }}
        </CTypo>
        <CTypo tstyle="title1" class="text-gray-800">
          {{ $n(payoutPendingAmount, 'currency') }}
        </CTypo>
      </div>
      <div
        v-show="loading"
        class="absolute top-0 left-0 w-full h-full bg-white z-2 flex items-center justify-center"
      >
        <CLoadingDots class="h-6 text-gray-500" />
      </div>
    </div>
    <Portal to="overlays">
      <CModal
        v-model="payoutModal"
        type="dialog"
        :submit-loading="submitLoading"
        @submit="createPayoutRequest"
      >
        <CTypo>
          {{ $t('payouts.wantToStartPayout', { sum: $n(balanceAvailableAmount, 'currency') }) }}
        </CTypo>
        <CTypo tstyle="footnote1" class="text-gray-500 mt-2">
          {{ $t('payouts.payoutDuration') }}
        </CTypo>
        <template v-slot:submit>
          {{ $t('payout.payoutSum', { sum: $n(balanceAvailableAmount, 'currency') }) }}
        </template>
      </CModal>
    </Portal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CREATE_PAYOUT, GET_BALANCE, GET_PENDING_PAYOUTS } from '@/store/actionTypes';
import {
  BALANCE_AVAILABLE_AMOUNT,
  BALANCE_PENDING_AMOUNT,
  BALANCE_TOTAL_AMOUNT,
  PAYOUT_PENDING_AMOUNT,
  THIS_MERCHANT,
} from '@/store/gettersTypes';
import plausible from '@/utils/plausible';

export default {
  data: () => ({
    payoutModal: false,
  }),

  computed: {
    ...mapGetters([
      BALANCE_AVAILABLE_AMOUNT,
      BALANCE_PENDING_AMOUNT,
      BALANCE_TOTAL_AMOUNT,
      PAYOUT_PENDING_AMOUNT,
      THIS_MERCHANT,
    ]),
    loading() {
      return this.$store.state.payouts.loading;
    },
    submitLoading() {
      return this.$store.state.payouts.submitLoading;
    },
  },

  created() {
    this.getBalance();
    this.getPendingPayouts();
  },

  methods: {
    ...mapActions([GET_BALANCE, GET_PENDING_PAYOUTS, CREATE_PAYOUT]),
    openModal() {
      this.getBalance(false);
      this.payoutModal = true;
    },
    createPayoutRequest() {
      this.createPayout().then(() => {
        plausible('Payout');
        this.payoutModal = false;
      });
    },
  },
};
</script>
