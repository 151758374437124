<template>
  <CModal
    v-bind="{ ...$attrs }"
    ref="modal"
    variant="dialog"
    submit-form="payoutAccount"
    :submit-loading="loading"
    no-cancel
    :button-props="{
      cancelText: $t('cancel')
    }"
    @close="$emit('close')"
  >
    <template v-slot:header>
      {{ $t('onboarding.addPayoutAccount') }}
    </template>
    <template>
      <form
        id="payoutAccount"
        @submit.prevent="submit"
      >
        <CTextField
          v-model="iban"
          :mask="ibanMask"
          :label="$t('iban')"
          required
          :custom-error="helper.ibanInvalid ? $t('ibanIsInvalid') : null"
          @input="resetValidation"
          @blur="validateIban"
        />
        <CSelect
          v-model="accountHolderType"
          :label="$t('accountHolderName')"
          required
        >
          <option value="individual">{{ $t('person') }}</option>
          <option value="company">{{ $t('company') }}</option>
        </CSelect>
        <CTextField
          v-model="accountHolderName"
          required
        />
      </form>
    </template>
  </CModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { IBAN } from 'ibankit';
import { THIS_MERCHANT } from '@/store/gettersTypes';
import { MASK_IBAN } from '@/constants/ui';
import { CREATE_PAYOUT_ACCOUNT } from '@/store/actionTypes';

export default {
  inheritAttrs: false,

  data: () => ({
    iban: null,
    accountHolderType: 'company',
    accountHolderName: null,
    currency: 'eur',
    country: null,
    loading: false,

    helper: {
      ibanInvalid: false,
    },
  }),

  computed: {
    ...mapGetters([THIS_MERCHANT]),
    ibanMask() {
      return MASK_IBAN;
    },
    fullIban() {
      return `DE${this.iban}`;
    },
  },

  created() {
    this.accountHolderName = this.thisMerchant.name;
    this.country = this.thisMerchant.countryShort;
  },

  methods: {
    validateIban() {
      this.helper.ibanInvalid = !IBAN.isValid(this.fullIban);
    },
    resetValidation() {
      this.helper.ibanInvalid = false;
    },
    submit() {
      if (!this.helper.ibanInvalid) {
        this.loading = true;
        const { accountHolderType, accountHolderName, currency, country } = this;
        this.$store
          .dispatch(CREATE_PAYOUT_ACCOUNT, {
            merchantId: this.thisMerchant.id,
            body: {
              iban: this.fullIban,
              accountHolderType,
              accountHolderName,
              currency,
              country,
            },
          })
          .then(() => {
            this.loading = false;
            this.$emit('close');
            this.iban = null;
            this.accountHolderType = 'company';
            this.accountHolderName = null;
            this.currency = 'eur';
            this.country = null;
            this.loading = false;
          });
      }
    },
  },
};
</script>
