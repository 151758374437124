<template>
  <Portal to="overlays">
    <AddPayoutAccount
      :show="currentAction === 'payoutAccount'"
      @close="currentAction = null"
    />
    <DeliveryAreas
      :show="currentAction === 'delivery'"
      @close="currentAction = null"
    />
    <MerchantWastes
      :show="currentAction === 'wasteTypes'"
      @close="currentAction = null"
    />
    <ProductContainers
      :show="currentAction === 'container'"
      @close="currentAction = null"
    />
  </Portal>
</template>

<script>
import AddPayoutAccount from './AddPayoutAccount.vue';
import DeliveryAreas from './DeliveryAreas/OnboardingModal.vue';
import MerchantWastes from './MerchantWastes/OnboardingModal.vue';
import ProductContainers from './ProductContainers/OnboardingModal.vue';

export default {
  components: {
    AddPayoutAccount,
    DeliveryAreas,
    MerchantWastes,
    ProductContainers,
  },

  data: () => ({
    currentAction: null,
    currentActionLoading: false,
  }),

  methods: {
    open(action) {
      this.currentAction = action;
    },
    addPayoutAccount() {
      this.currentActionLoading = true;
      setTimeout(() => {
        this.currentActionLoading = false;
        this.currentAction = null;
      }, 1000);
    },
  },
};
</script>
