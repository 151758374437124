<template>
  <CContainer v-if="needsOnboarding">
    <OnboardingSteps @action="runAction" />
    <OnboardingActions ref="actions" />
  </CContainer>
  <div v-else>
    <Portal to="subnav-content">
      <BalanceGrid />
    </Portal>
    <CContainer>
      <HomeOrders />
    </CContainer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { THIS_MERCHANT, WINDOW_HEIGHT, NEEDS_ONBOARDING } from '@/store/gettersTypes';
import { OnboardingSteps, OnboardingActions } from '@/ui/components/Merchant';
import BalanceGrid from '@/ui/components/Merchant/BalanceGrid.vue';
import HomeOrders from '@/ui/components/Merchant/HomeOrders.vue';

export default {
  components: {
    OnboardingSteps,
    OnboardingActions,
    BalanceGrid,
    HomeOrders,
  },

  computed: {
    ...mapGetters([THIS_MERCHANT, WINDOW_HEIGHT, NEEDS_ONBOARDING]),
  },

  methods: {
    runAction(action) {
      this.$refs.actions.open(action);
    },
  },
};
</script>
